import React, {Component} from "react";
import divider from "./img/misc/divider.png";
import curtains from './img/stage-scene/curtains.png'
import floorplanks from "./img/stage-scene/floorplanks2.png";
import act1 from "./img/stage-scene/act1.png";
import {LIST1, LIST2, LIST3} from "./copy";

class LockedSection extends Component {
    state={
        numDots:1
    }
    componentDidMount() {
        setInterval(()=>this.setState({numDots: (this.state.numDots + 1) % 3}), 1000   )
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <img src={divider} style={{position: 'absolute', top: -32, zIndex: 999}} className='w-100'/>
                <div style={{position: 'relative', height: 1020, overflow: 'hidden'}} className='curtainbg'>
                    <div className='locked-section'>
                        <div className='locked-header mt-10'>
                            {this.props.title}.{".".repeat(this.state.numDots)}
                        </div>
                        <div className='locked-desc'>
                            {this.props.desc}
                        </div>
                        <div className='mt-5 roadmapcard'>
                            <div className='row-plain mb-3'>
                                <div className='mr-6'>
                                    <div className='listlabel mb-4' style={{fontSize: 24}}>Phase 1: Bake</div>
                                    <div>
                                        {LIST1.map(item =>
                                            <div key={item.title} className='row-ac mb-2'>
                                                <div
                                                    className={item.complete ? 'checkbox mr-2' : 'checkbox-inactive mr-2'}>
                                                    {item.complete &&
                                                    <div className='filledcheck'>
                                                        <div className='bottom'/>
                                                        <div className='right'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='listitem-title' style={{fontSize: 24, maxWidth: 300}}>
                                                    {item.title}
                                                    <div className='listitem-date' style={{fontSize: 22}}>
                                                        {item.date}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className='listlabel mb-4' style={{fontSize: 24}}>Phase 2: Batter</div>
                                    <div>
                                        {LIST2.map(item =>
                                            <div key={item.title} className='row-ac mb-2'>
                                                <div
                                                    className={item.complete ? 'checkbox mr-2' : 'checkbox-inactive mr-2'}>
                                                    {item.complete &&
                                                    <div className='filledcheck'>
                                                        <div className='bottom'/>
                                                        <div className='right'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='listitem-title' style={{fontSize: 24, maxWidth: 300}}>
                                                    {item.title}
                                                    <div className='listitem-date' style={{fontSize: 22}}>
                                                        {item.date}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row-plain'>
                                <div className='mr-6'>
                                    <div className='listlabel mb-4' style={{fontSize: 24}}>Phase 3: Share</div>
                                    <div>
                                        {LIST3.map(item =>
                                            <div key={item.title} className='row-ac mb-2'>
                                                <div
                                                    className={item.complete ? 'checkbox mr-2' : 'checkbox-inactive mr-2'}>
                                                    {item.complete &&
                                                    <div className='filledcheck'>
                                                        <div className='bottom'/>
                                                        <div className='right'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='listitem-title' style={{fontSize: 24, maxWidth: 300}}>
                                                    {item.title}
                                                    <div className='listitem-date' style={{fontSize: 22}}>
                                                        {item.date}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className='listlabel mb-4' style={{fontSize: 24}}>Phase 4: TBD</div>
                                    <div>
                                        More announcements coming soon...
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='row-ac mt-4'>
                                    <div className='progress-vborder'/>
                                    <div className='w-100'>
                                        <div className='progress-hborder'/>
                                        <div className='progressbar-wrapper'>
                                            <div className='progressbar'>
                                            </div>
                                            <div className='highlight'/>
                                        </div>
                                        <div className='progress-hborder'/>
                                    </div>
                                    <div className='progress-vborder'/>
                                </div>
                                <div className='mt-2'>
                                    {this.props.title}.{".".repeat(this.state.numDots)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={floorplanks} style={{
                        position: 'absolute',
                        top: -(-1350 + this.props.scrollPosition * .15),
                    }}
                         className='w-100'/>
                    <img src={act1} style={{
                        position: 'absolute',
                        top: -(-1150 + this.props.scrollPosition * .15),
                        width: 400,
                        right: 280
                    }}/>
                    <img src={curtains} style={{
                        position: 'absolute',
                        top: -(-240 + this.props.scrollPosition * .1),
                    }}
                         className='w-100'/>
                </div>
            </div>
        )
    }
}

export default LockedSection
