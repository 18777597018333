import React, {Component} from "react";
import divider from "../img/misc/divider.png";


class MyMuffinsOverview extends Component {
    render() {
        return (
            <div className='row-ac-jc w-100 mb-6'>
                <div className='overview-stat-wrapper'>
                    <div className='tile-header'>
                        <div>
                            Total Muffinz
                        </div>
                    </div>
                    <div className='tile-stat'>
                        {this.props.staked.length + this.props.unstaked.length}
                    </div>
                </div>
                <div className='overview-stat-wrapper'>
                    <div className='tile-header'>
                        <div>
                            Staked
                        </div>
                    </div>
                    <div className='tile-stat'>
                        {this.props.staked.length}<span
                        className='tile-stat-sm'>/{this.props.staked.length + this.props.unstaked.length}</span>
                    </div>
                </div>
                {/*<div className='overview-stat-wrapper'>*/}
                {/*    <div className='tile-header'>*/}
                {/*        <div>*/}
                {/*            Collection Rarity*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='tile-stat'>*/}
                {/*        22%*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='overview-stat-wrapper'>
                    <div className='tile-header'>
                        <div>
                            $WEB Price
                        </div>
                    </div>
                    <div className='tile-stat'>
                        $420
                    </div>
                </div>
                <div className='overview-stat-wrapper'>
                    <div className='tile-header'>
                        <div>
                            Total Web Accrued
                        </div>
                    </div>
                    <div className='tile-stat'>
                        300<span className='tile-stat-sm'> $WEB</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default MyMuffinsOverview
