import React, {Component} from "react";
import divider from "../img/misc/divider.png";


class MuffinShelf extends Component {
    render() {
        return (
            <div className='w-100'>
                <div className='staking-container'>
                    <div className='staking-header'>
                        <div className='shelf-title'>
                            {this.props.staked ? "Staked Muffinz" : "Unstaked Muffinz"}
                        </div>
                        {this.props.staked ?
                            <div className='row-ac'>
                                <div className='shelf-stat'>
                                    <div className='shelf-stat-label'>
                                        Total Staked
                                    </div>
                                    <div>
                                        {this.props.muffins.length}
                                    </div>
                                </div>
                                <div className='shelf-stat'>
                                    <div className='shelf-stat-label'>
                                        Earned
                                    </div>
                                    <div>
                                        302 $WEB
                                    </div>
                                </div>
                                <div className='shelf-stat'>
                                    <div className='shelf-stat-label'>
                                        Earn Rate
                                    </div>
                                    <div>
                                        4 $WEB / day
                                    </div>
                                </div>
                                 <button className='mb-3 ml-4'>
                                    Unstake All
                                </button>
                            </div>
                            :
                            <div className='row-ac'>
                                <div className='shelf-stat'>
                                    <div className='shelf-stat-label'>
                                        Total Muffinz
                                    </div>
                                    <div>
                                        {this.props.muffins.length}
                                    </div>
                                </div>
                                 <button className='mb-3 ml-4'>
                                    Stake All
                                </button>
                            </div>
                        }
                    </div>
                    <div className='w-100 relative'>
                        <img src={divider} style={{position: 'absolute', top: -32, zIndex: 999}} className='w-100'/>
                        <div className='p-4 pt-6 pb-0 staking-container-inner'>

                            {this.props.muffins.map((muffin) =>
                                <div className='ml-2 mr-2 mb-5 muffin-item'>
                                    <div className='hover14 shinetile hover-shadow ml-0 mr-0 pointer' onClick={() => this.props.toggleMuffinModal(muffin)}>
                                        <figure>
                                            <img src={muffin.image}
                                                 style={{width: 255, height: 250, marginTop: -25, marginLeft: -45}}/>
                                        </figure>
                                    </div>
                                    <div className='row-ac-jb'>
                                        <div>
                                            <p className='m-0 mt-05'>{muffin.name}</p>
                                            <p className='m-0 mt-05'>Ev: {muffin.evolution}</p>
                                        </div>
                                        <div style={{textAlign: 'right'}}>
                                            <p className='m-0 mt-05'>Rarity</p>
                                            <p className='m-0 mt-05 press-start'>{muffin.rarity}%</p>
                                        </div>
                                    </div>
                                    <div className='row-ac-jb mt-2'>
                                        {this.props.staked ?
                                            <div className='shelf-stat-label'>
                                                Staked {muffin.staked}
                                            </div>
                                            :
                                            <button>
                                                Stake
                                            </button>
                                        }
                                        <button onClick={() => this.props.toggleMuffinModal(muffin)} style={{marginLeft: this.props.staked && 'auto'}}>
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MuffinShelf
