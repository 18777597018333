import React, {Component} from "react";
import divider from "./img/misc/mobileborder.png";
import curtains from './img/stage-scene/curtains.png'
import floorplanks from "./img/stage-scene/floorplanks2.png";
import act1 from "./img/stage-scene/act1.png";

class LockedSectionMobile extends Component {
    state = {
        numDots: 1
    }

    componentDidMount() {
        setInterval(() => this.setState({numDots: (this.state.numDots + 1) % 3}), 1000)
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <img src={divider} style={{position: 'absolute', top: -26, zIndex: 999}} className='w-100'/>
                <div style={{position: 'relative', height: 320, overflow: 'hidden'}} className='curtainbg'>
                    <div className='locked-section' style={{height: 320}}>
                        <div className='locked-header' style={{fontSize: 32}}>
                            {this.props.title}.{".".repeat(this.state.numDots)}
                        </div>
                        <div className='locked-desc mr-3' style={{fontSize: 18}}>
                            {this.props.desc}
                        </div>
                    </div>
                    <img src={floorplanks} style={{
                        position: 'absolute',
                        top: -(-350 + this.props.scrollPosition * .05),
                    }}
                         className='w-100'/>
                    <img src={act1} style={{
                        position: 'absolute',
                        top: -(-290 + this.props.scrollPosition * .05),
                        width: 100,
                        right: 80
                    }}/>
                    <img src={curtains} style={{
                        position: 'absolute',
                        top: -(-110 + this.props.scrollPosition * .07),
                    }}
                         className='w-100'/>
                </div>
            </div>
        )
    }
}

export default LockedSectionMobile
