export const LIST1 = [
    {
        title: 'Build a strong and welcoming Discord community',
        date: 'Q1',
        complete: true
    },
    {
        title: 'Daily crypto and NFT analytics',
        date: 'Q1',
        complete: true
    },
    {
        title: 'Collaborations and WL spots for the community',
        date: 'Q1',
        complete: true
    },
    {
        title: 'SparkleMuffinz Mint',
        date: 'Q1'
    }
]

export const LIST2 = [
    {
        title: 'Establish community treasury and SparkleDAO',
        date: 'Late Q1'
    },
    {
        title: 'Enable $WEB utility token and seed Uniswap with liquidity',
        date: 'Late Q1'
    },
    {
        title: 'Enable SparkleMuffinz upgrade capability in the Bakery',
        date: 'Late Q1'
    }
]

export const LIST3 = [
    {
        title: 'Build a unique, immersive 3D environment including P2E and social events for holders',
        date: 'Early Q2'
    },
    {
        title: 'Build a free, claimable 3d render for use in the SparkleMuffinz NFT World',
        date: 'Early Q2'
    }

]
export const LIST4 = [
    {
        title: 'Plan additional drops and opportunities for the SparkleMuffinz community',
        date: 'TBD'
    },
]

export const LOCK_SPLASH = [
    {
        title: 'Mint baking',
        desc: 'Mint Live Now'
    }
]

export const ATTRIBUTES = [
    {
        amt: '--',
        title: 'Muffinz'
    },
    {
        amt: '--',
        title: 'Eyes'
    },
    {
        amt: '--',
        title: 'Mouths'
    },
    {
        amt: '--',
        title: 'Dances'
    },
    {
        amt: '--',
        title: 'Backgrounds'
    },
]

export const ATTRIBUTES_SUBTEXT = 'Traits coming soon! Each SparkleMuffin has a unique combination of traits, all with varying rarities and looks that are upgradeable through our bakery. Oh, and some dance too...'

export const QADATA = [
    {
        question: 'What is a SparkleMuffin?',
        answer: 'A colorful and vibratory species of jumping spider known for their elaborate dances.',
    },
    {
        question: 'When and where can I mint a SparkleMuffin?',
        answer: 'Minting in Dec 2021, date TBA. SparkleMuffinz will be available to mint for 24 hours on our official page for whitelist members. All unclaimed Muffinz will be available for public mint.',
    },
    {
        question: 'How do I get a whitelist role?',
        answer: 'We have partnered with other projects to allocate a portion of the spots and we will fairly distribute the rest to early and active Discord members.',
    },
    {
        question: 'Is there a community wallet?',
        answer: 'Yes! We are allocating 10% of mint proceeds and 10% of secondary proceeds to a community wallet for Muffinz events and projects.',
    },
    {
        question: 'What is the long term plan for the SparkleMuffinz project?',
        answer: 'SparkleMuffinz is the first of a series of projects from our team.  This will be a platform to bring together the crypto community and support a wide range of projects.  We are working with different artists and charities as diverse as our team on a lot of exciting things in the future.',
    },
    {
        question: 'What are daily Muffinz?',
        answer: 'Our daily thoughts and charts on interesting projects, performance benchmarking, and the broader NFT and crypto market. Not investment advice, but our thoughts on what has legs!',
    },
]

export const BENEFITS_BLURB = 'Getting baked means you get to:'

export const HOLDER_BENEFITS = [
    'Being part of a strong community and network helping each other get ahead in the NFT and crypto space',
    'Daily Muffinz market analytics and commentary on Discord',
    '$WEB yielding NFT',
    'Access to the Oven to upgrade your NFT',
    'Voting power in SparkleDao for community-inspired events and the community Treasury',
    'Access to a unique 3d environment for P2E and social activities',
    'Mint pass for upcoming projects from New Forward Thinking'
]

export const mintTime = new Date(2021, 11, 9, 15);

export const DISCLAIMER = 'The Information on this website, Muffinz Podcasts, or any other SparkleMuffinz publication (together, “SparkleMuffinz Content”), is provided for educational, informational, and entertainment purposes only, without any express or implied warranty of any kind, including warranties of accuracy, completeness, or fitness for any particular purpose.  Nothing in the SparkleMuffinz Content is intended to be financial advice, investment advice, trading advice, legal advice, tax advice, or advice to enter into any particular financial transaction.  The SparkleMuffinz Content is general in nature and is not specific to you the user or anyone else.  You should not make any decision, financial, investment, trading or otherwise, based on any information  in the SparkleMuffinz Content without undertaking independent due diligence and consultation with appropriate legal counsel and investment professionals.'

export const QUIZ = [
    {
        question: 'This Ph.D. student at the University of California, Berkeley first discovered real-life SparkleMuffins and coined their nicknames. What a legend.',
        answers: ['girard', 'maddie girard']
    },
    {
        question: 'Popular among the Ashanti people of Ghana, this spidey rescued all of the stories from Sky-God Nyame to share with the world.',
        answers: ['anansi', 'ananse']
    },
    {
        question: 'This baking phenom is half of the world\'s oddest celebrity friendships. She first met Snoop Dogg while mashing potatoes.',
        answers: ['stewart', 'martha stewart']
    },
    {
        question: 'Pancakes, pikelets, crompid cake.',
        answers: ['crumpet', 'crumpets']
    },
    {
        question: 'A traveller gave me to Hagrid when I was an egg.',
        answers: ['aragog']
    },
    {
        question: 'This Spiderman villain was inspired by the Tex Avery cartoon, Bad Luck Blackie.',
        answers: ['black cat', 'felicia hardy']
    },
    {
        question: 'This Muffinz team member started his first company before high school.',
        answers: ['Chocolate Chip', 'chocolate chip muffin']
    },
    {
        question: 'While it’s hard to overdose on this popular muffin, it can still spoil a drug test. ',
        answers: ['poppyseed muffin', 'poppyseed muffins', 'poppy seed', 'poppyseed', 'poppy seed muffin', 'poppy seed muffins']
    },
    {
        question: 'This Australian-originated slang has become an apt descriptor for overzealous waistline fat.',
        answers: ['muffin top']
    },
    {
        question: 'Wilbur chose this name for the daughter of his arachnid savior.',
        answers: ['nellie']
    },
]

export const CYPHER = [
    ['QWFKJMNBSEWSFO', 'YOUARECRAWLING'],
    ['NVGAPBLWDEU', 'CLOSERTOSUC'],
    ['KPCKXPBZIACQWF', 'CESSPERHAPSYOU'],
    ['CZWFVVQYCHM', 'SHOULDINSPE'],
    ['NDQWFBKCCBGCY', 'CTYOURSURROUN'],
    ['NAVRCOPTVWG', 'DINGSWHILEY'],
    ['ZESZPRWZPGWL', 'OUAREHEREWED'],
    ['ZXGBHKFBJY', 'ONOTWANTYO'],
    ['MBZLWQYMGVDY', 'UTOBEINCONSO'],
    ['DIMVWTLDWZ', 'LABLELATER'],
]
