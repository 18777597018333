import React, {Component} from "react";
import divider from "./img/misc/mobileborder.png";
import {BENEFITS_BLURB, HOLDER_BENEFITS} from "./copy";

export default class BenefitsMobile extends Component {
    state = {
        numDots: 1
    }

    componentDidMount() {
        setInterval(() => this.setState({numDots: (this.state.numDots + 1) % 3}), 1000)
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='p-4 mobile-roadmap'>
                    <h2 style={{fontSize: 30}} className='mt-6'>Muffinz Holder Benefits</h2>
                    <p className='mt-4' style={{fontSize:18}}>
                        {BENEFITS_BLURB}
                    </p>
                    <ol className='pl-2' style={{fontSize:18}}>
                        {HOLDER_BENEFITS.map((benefit, i) =>
                            <li style={{listStyle: "none"}} className='mb-1'>
                                <span className='yellow' style={{fontWeight: '600'}}>{i + 1}.</span> {benefit}
                            </li>
                        )}
                    </ol>
                </div>
            </div>
        );
    }
}
