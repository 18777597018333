import React, {Component} from "react";
import muffin from '../img/muffins/muffin4.JPG';
import muffin2 from '../img/muffins/muffin5.GIF';
import muffin11 from '../img/muffins/muffin11.gif'
import muffin12 from '../img/muffins/muffin12.gif'
import muffin13 from '../img/muffins/muffin13.gif'
import muffin14 from '../img/muffins/muffin14.gif'
import muffin15 from '../img/muffins/muffin15.gif'
import muffin16 from '../img/muffins/muffin16.JPG'
import muffin17 from '../img/muffins/muffin17.gif'
import muffin18 from '../img/muffins/muffin18.gif'
import muffin19 from '../img/muffins/muffin19.png'
import MuffinShelf from "./MuffinShelf";
import MyMuffinsOverview from "./MyMuffinsOverview";
import Modal from 'react-modal';
import {motion} from "framer-motion";
import MuffinModal from "./MuffinModal";
import Navbar from "../Navbar";
import PantryNavbar from "../PantryNavbar";
import Footer from "../Footer";


const STAKED = [
    {
        name: 'Muffin 2910',
        rarity: 12,
        evolution: 1,
        image: muffin2,
        staked: 'Dec 22',
        dance: 'none',
        muffin: 47,
        eye_style: 5,
        eye_color: 2,
        mouth: 6,
        body_color: 16,
        head_extra: 1,
        hand_extra: 1,
        arm_position: 5,
        leg_position: 1,
        eye_position: 1,
        mouth_position: 1,
        background: 1,
    },
    {
        name: 'Muffin 4320',
        rarity: 1,
        evolution: 2,
        image: muffin18,
        staked: 'Dec 24'
    },
]

const MUFFINS = [
    {
        name: 'Muffin 2910',
        rarity: 13,
        evolution: 1,
        image: muffin11
    },
    {
        name: 'Muffin 2910',
        rarity: 45,
        evolution: 1,
        image: muffin12
    },
    {
        name: 'Muffin 2910',
        rarity: 66,
        evolution: 1,
        image: muffin13
    },
    {
        name: 'Muffin 2910',
        rarity: 43,
        evolution: 1,
        image: muffin14
    },
    {
        name: 'Muffin 2910',
        rarity: 45,
        evolution: 1,
        image: muffin15
    },
    {
        name: 'Muffin 2910',
        rarity: 3203,
        evolution: 1,
        image: muffin19
    },
    {
        name: 'Muffin 2910',
        rarity: 12,
        evolution: 1,
        image: muffin17,
        staked: 'Dec 22'
    },
    // {
    //     name: 'Muffin 4320',
    //     rarity: 1,
    //     evolution: 2,
    //     image: muffin18,
    //     staked: 'Dec 24'
    // },
]

class MyMuffins extends Component {
    state = {
        muffinModal: false,
        selectedMuffin: {},
        scrollPosition: 0,
        mobile: window.innerWidth <= 760,
        tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth),
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        this.setState({
            scrollPosition: winScroll,
        })

    }


    componentDidMount() {
        if (!this.state.tablet) {
            window.addEventListener('scroll', this.listenToScroll)
            window.addEventListener("resize", this.resize.bind(this));
        }
        this.resize();
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth)
        });
    }

    componentWillUnmount() {
        if (!this.state.tablet) {
            window.removeEventListener('scroll', this.listenToScroll)
            window.removeEventListener("resize", this.resize.bind(this));
        }
    }

    toggleMuffinModal = (selectedMuffin) => {
        this.setState({muffinModal: !this.state.muffinModal, selectedMuffin})
    }

    render() {
        return (
            <div>
                <PantryNavbar
                    mobile={this.state.mobile}
                    scrollPosition={this.state.scrollPosition}
                />
                <div className='col-ac-jc pl-10 pr-10 pb-10 mb-10'>
                    <h1 style={{marginTop:'10%'}}>
                        Your Pantry
                    </h1>
                    <p className='mb-5 text-center w-70'>
                        All your Muffinz in one place. Stake your spideys, earn rewards, view rarity and attributes, and
                        grow your
                        collection!
                    </p>
                    <MyMuffinsOverview staked={STAKED} unstaked={MUFFINS}/>
                    <MuffinShelf muffins={STAKED} staked toggleMuffinModal={this.toggleMuffinModal}/>
                    <div className='mt-6'/>
                    <MuffinShelf muffins={MUFFINS} toggleMuffinModal={this.toggleMuffinModal}/>
                    <MuffinModal
                        isOpen={this.state.muffinModal}
                        selectedMuffin={this.state.selectedMuffin}
                        toggleMuffinModal={this.toggleMuffinModal}
                    />
                </div>
                <Footer/>
            </div>
        );
    }
}

export default MyMuffins;
