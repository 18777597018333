import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import metamask from '../img/misc/MetaMask_Fox.svg.png'
import walletconnect from '../img/misc/a5169900-c66c-11e9-8592-33c7334dfd6d.png'

class ConnectWalletModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggleMuffinModal}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x' onClick={this.props.toggle}>
                        X
                    </div>
                    <div className='col-ac'>
                        <div className='modal-main-stats'>
                            <div className='ml-3 mr-3'>
                                <div>
                                    Connect your wallet
                                </div>
                            </div>
                        </div>
                        <div className='row-ac-jc'>
                            <div className='wallet-option' onClick={()=>this.props.triggerProvider("metamask")}>
                                <img src={metamask} style={{width:50, height:50}} className='mt-1'/>
                                <p className='mb-1 mt-1'>Metamask</p>
                                <p className=' mt-0 mobile-subname'>Connect to your Metamask Wallet</p>
                            </div>
                            <div className='wallet-option' onClick={()=>this.props.triggerProvider("walletconnect")}>
                                <img src={walletconnect} style={{width:50, height:50}} className='mt-1'/>
                                <p className='mb-1 mt-1'>WalletConnect</p>
                                <p className=' mt-0 mobile-subname'>Scan with WalletConnect to connect</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default ConnectWalletModal
