import React, {Component} from "react";
import divider from "./img/misc/mobileborder.png";
import {LIST1, LIST2, LIST3} from "./copy";

export default class RoadmapMobile extends Component {
    state = {
        numDots: 1
    }

    componentDidMount() {
        setInterval(() => this.setState({numDots: (this.state.numDots + 1) % 3}), 1000)
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <div className='p-2' className='mobile-roadmap'>
                    <div className='mt-5 roadmapcard p-3 mb-5'>
                        <div className='mb-3'>
                            <div className='listlabel mb-2' style={{fontSize: 16}}>Phase 1: Bake</div>
                            <div className='w-100'>
                                {LIST1.map(item =>
                                    <div key={item.title} className='row-ac mb-2 w-100'>
                                        <div style={{minWidth: 45}}>
                                            <div
                                                className={item.complete ? 'checkbox mr-2' : 'checkbox-inactive mr-2'}>
                                                {item.complete &&
                                                <div className='filledcheck'>
                                                    <div className='bottom'/>
                                                    <div className='right'/>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontSize: 18}}>
                                                {item.title}
                                            </div>
                                            <div style={{fontSize: 18, color: 'lightgrey'}}>
                                                {item.date}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='mb-3'>
                            <div className='listlabel mb-2' style={{fontSize: 16}}>Phase 2: Batter</div>
                            <div className='w-100'>
                                {LIST2.map(item =>
                                    <div key={item.title} className='row-ac mb-2 w-100'>
                                        <div style={{minWidth: 45}}>
                                            <div
                                                className={item.complete ? 'checkbox' : 'checkbox-inactive'}>
                                                {item.complete &&
                                                <div className='filledcheck'>
                                                    <div className='bottom'/>
                                                    <div className='right'/>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontSize: 18}}>
                                                {item.title}
                                            </div>
                                            <div style={{fontSize: 18, color: 'lightgrey'}}>
                                                {item.date}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='listlabel mb-2' style={{fontSize: 16}}>Phase 3: Share</div>
                            <div className='w-100'>
                                {LIST3.map(item =>
                                    <div key={item.title} className='row-ac mb-2 w-100'>
                                        <div style={{minWidth: 45}}>
                                            <div
                                                className={item.complete ? 'checkbox mr-2' : 'checkbox-inactive mr-2'}>
                                                {item.complete &&
                                                <div className='filledcheck'>
                                                    <div className='bottom'/>
                                                    <div className='right'/>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontSize: 18}}>
                                                {item.title}
                                            </div>
                                            <div style={{fontSize: 18, color: 'lightgrey'}}>
                                                {item.date}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='row-ac mt-4'>
                                <div className='progress-vborder'/>
                                <div className='w-100'>
                                    <div className='progress-hborder'/>
                                    <div className='progressbar-wrapper'>
                                        <div className='progressbar'>
                                        </div>
                                        <div className='highlight'/>
                                    </div>
                                    <div className='progress-hborder'/>
                                </div>
                                <div className='progress-vborder'/>
                            </div>
                            <div className='mt-2'>
                                Mint Baking.{".".repeat(this.state.numDots)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
