import './Formatting.css'
import './App.css';
import {Component} from "react";
import divider from "./img/misc/divider.png";
import bgwires from "./img/wires-scene/bgwires.png";
import powerlines1 from "./img/wires-scene/powerline1.png";
import powerlines2 from "./img/wires-scene/powerline2.png";
import muffin1 from './img/muffins/muffin1.GIF'
import muffin2 from './img/muffins/muffin2.GIF'
import muffin5 from './img/muffins/muffin5.GIF'
import muffin6 from './img/muffins/muffin6.gif'
import muffin7 from './img/muffins/muffin7.gif'
import muffin8 from './img/muffins/muffin8.gif'
import muffin9 from './img/muffins/muffin9.gif'
import muffin10 from './img/muffins/muffin10.gif'
import muffin11 from './img/muffins/muffin11.gif'
import muffin12 from './img/muffins/muffin12.gif'
import muffin13 from './img/muffins/muffin13.gif'
import muffin14 from './img/muffins/muffin14.gif'
import muffin15 from './img/muffins/muffin15.gif'
import muffin16 from './img/muffins/muffin16.JPG'
import muffin17 from './img/muffins/muffin17.gif'
import muffin18 from './img/muffins/muffin18.gif'
import muffin19 from './img/muffins/muffin19.png'

class Team extends Component {
    render() {
        return (
            <div style={{position: 'relative'}} className='teambg'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <img src={bgwires} style={{
                    position: 'absolute',
                    top: -(-1000 + this.props.scrollPosition * .18),
                    right: 0,
                    left: 0,
                }}/>
                <div className='teamcontainer'>
                    <div className='col-ac mt-10 w-50'>
                        <div>
                            <h2>Team</h2>
                            <p className='mb-5'>
                                New Forward Thinking (NFT) is a group who draws inspiration from the diversity of our
                                own community, with the goal of bringing on surprising change. Stay tuned to see what we
                                do next.
                            </p>
                        </div>
                    </div>

                    <div>
                        <img src={powerlines1} style={{
                            position: 'absolute',
                            top: -(-2700 + this.props.scrollPosition * .45),
                            right: 0,
                            left: 0,
                        }}/>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-2900 + this.props.scrollPosition * .45),
                                left: 120,
                                height: 200,
                                width: 200
                            }}
                        >
                            <div className='relative row-ac'
                            >
                                <div>
                                    <img src={muffin5} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Creme Brulee Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Architect
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -190, top: -70}}>
                                    <div className='mt-2'>
                                        Carrot Cake Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Baker
                                    </div>
                                    <img src={muffin11} style={{
                                        width: 175
                                    }}/>
                                </div>
                                <div className='absolute' style={{right: -420, top: -10}}>
                                    <img src={muffin1} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Pumpkin Spice Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Microscope
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -690, top: -130}}>
                                    <div className='mt-2'>
                                        Breakfast Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Giver
                                    </div>
                                    <img src={muffin7} style={{
                                        width: 175
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <img src={powerlines2} style={{
                            position: 'absolute',
                            top: -(-4650 + this.props.scrollPosition * .7),
                            right: 0,
                            left: 0,
                        }}/>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-4700 + this.props.scrollPosition * .7),
                                left: 50,
                                height: 200,
                                width: 200
                            }}
                        >
                            <div className='relative row-ac'
                            >
                                <div>
                                    <img src={muffin8} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Blueberry Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Creator
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -200, top: -70}}>
                                    <div className='mt-2'>
                                        Chocolate Chip Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Dev
                                    </div>
                                    <img src={muffin2} style={{
                                        width: 175
                                    }}/>
                                </div>
                                <div className='absolute' style={{right: -420, top: 20}}>
                                    <img src={muffin19} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Coconut Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Webmaster
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -660, top: -30}}>
                                    <div className='mt-2'>
                                        Lemon Poppyseed Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        L'artiste
                                    </div>
                                    <img src={muffin15} style={{
                                        width: 175
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <img src={powerlines1} style={{
                            position: 'absolute',
                            top: -(-5600 + this.props.scrollPosition * .81),
                            right: 0,
                            left: 0,
                        }}/>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-5800 + this.props.scrollPosition * .81),
                                left: 120,
                                height: 200,
                                width: 200
                            }}
                        >
                            <div className='relative row-ac'
                            >
                                <div>
                                    <img src={muffin12} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Plain Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Crypto Guy
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -190, top: -70}}>
                                    <div className='mt-2'>
                                        English Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Mad Scientist
                                    </div>
                                    <img src={muffin13} style={{
                                        width: 175
                                    }}/>
                                </div>
                                <div className='absolute' style={{right: -420, top: -10}}>
                                    <img src={muffin14} style={{
                                        width: 175
                                    }}/>
                                    <div className='mt-2'>
                                        Banana Nut Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        Twitter Fingerz
                                    </div>
                                </div>
                                <div className='absolute' style={{right: -690, top: -120}}>
                                    <div className='mt-2'>
                                        Red Velvet Muffin
                                    </div>
                                    <div className='memberlabel'>
                                        The Konditor
                                    </div>
                                    <img src={muffin6} style={{
                                        width: 175
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default Team;
