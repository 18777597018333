import React, {Component} from "react";
import muffin from '../img/muffins/muffin4.JPG';
import muffin2 from '../img/muffins/muffin5.GIF';
import muffin11 from '../img/muffins/muffin11.gif'
import muffin12 from '../img/muffins/muffin12.gif'
import muffin13 from '../img/muffins/muffin13.gif'
import muffin14 from '../img/muffins/muffin14.gif'
import muffin15 from '../img/muffins/muffin15.gif'
import muffin16 from '../img/muffins/muffin16.JPG'
import muffin17 from '../img/muffins/muffin17.gif'
import muffin18 from '../img/muffins/muffin18.gif'
import muffin19 from '../img/muffins/muffin19.png'
import MuffinShelf from "./MuffinShelf";
import MyMuffinsOverview from "./MyMuffinsOverview";
import Modal from 'react-modal';
import {motion} from "framer-motion";
import MuffinModal from "./MuffinModal";
import Navbar from "../Navbar";
import PantryNavbar from "../PantryNavbar";
import Footer from "../Footer";

class QuizQuestion extends Component {
    state = {
        answerMatch: false,
        answer: '',
    }

    checkAnswer = () => {
        this.props.answers.map(answer => {
            if (answer === this.state.answer.toLowerCase()) {
                this.setState({answerMatch: true})
            }
        })
        this.props.checkAnswer(this.props.i, this.state.answer, this.props.answers);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.answer !== this.state.answer) {
            this.checkAnswer()
        }
    }

    render() {
        return (
            <div className='quiz-question'>
                <h3 style={{fontSize: this.props.mobile && 20}} className='mr-1'>
                    <span className='yellow'>{this.props.i + 1}.</span>
                    {this.props.question}
                </h3>
                <div className='row-ac'>
                    <input type='text' className='quiz-answer-input'
                           placeholder={'Your answer'}
                           value={this.state.answer}
                           onChange={(e) => this.setState({answer: e.target.value})}
                    />
                    <div style={{width: 75,}}>
                        {this.state.answerMatch || this.props.answered ?
                            <div className={'checkbox ml-3'}>
                                <div className='filledcheck'>
                                    <div className='bottom'/>
                                    <div className='right'/>
                                </div>
                            </div>
                            :
                            <div className={'checkbox-inactive ml-3'}>
                                <div className='redcheck'>
                                    <div className='bottom'/>
                                    <div className='right'/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default QuizQuestion;
