import './App.css';
import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {Collapse} from "react-collapse/lib/Collapse";
import {Link} from "react-router-dom";
import MintButton from "./components/MintButton";
import ProgressBar from "./components/ProgressBar";
import SvgLink from "./icons/Link";
import {ethers} from 'ethers';
import { CONTRACT_ADDRESS, ABI} from './utils/consts';

const ROUTES = [
    {
        name: 'About',
        ref: 'about',
    },
    {
        name: 'Roadmap',
        ref: 'roadmap',
    },
    {
        name: 'Benefits',
        ref: 'benefits',
    },
    {
        name: 'Team',
        ref: 'team',
    },
    {
        name: 'FAQ',
        ref: 'faq',
    },
]

const variants = {
    collapsed: {
        height: 24
    },
    open: {
        height: 80
    }
}

const wait = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hoveringNav: false,
            mobileNavOpen: false,
            numDots: 1,
            numMinted: 0,
            numWhiteListed: 0,
            walletConnected: false
        }
        this.provider = ethers.getDefaultProvider()
        this.contract = new ethers.Contract(CONTRACT_ADDRESS, ABI)
    }

    hoverNav = () => {
        if (this.props.scrollPosition >= 100) {
            wait(350).then(() => this.setState({hoveringNav: true}))
        }
    }



    getTotalSupply = async () => {
        const readContract = this.contract.connect(this.provider)
        let totalSupply = await readContract.totalSupply()
        this.setState({ numMinted: parseInt(totalSupply)})
        return parseInt(totalSupply)
    }

    changeNumWhiteListed = (num) => {
        this.setState({numWhiteListed: num});
    }

    setMintedSupply = (num) => {
        this.setState({numMinted: num});
    }

    componentDidMount() {
        this.getTotalSupply()
        setInterval(() => this.setState({numDots: (this.state.numDots + 1) % 3}), 1000)
    }

    render() {
        if (this.props.mobile) {
            return (
                <div className='header-mobile'
                     style={{
                         height: (((this.props.scrollPosition <= 100) || this.state.hoveringNav) && !this.state.mobileNavOpen) ? 55 : this.state.mobileNavOpen ? '100vh' : 55,
                         backgroundColor: this.state.mobileNavOpen && '#000000C0',
                         borderBottom: this.props.scrollPosition <= 200 ? '3px solid #003459' : '3px solid #492726'
                     }}
                >
                    <Collapse isOpen={this.props.mintPressed}>
                        <div className='mint-page p-4' style={{
                            height: this.props.mintPressed ? '100vh' : 0,
                            opacity: !this.props.mintPressed && 0,
                            zIndex: 0
                        }}>
                            <h1 style={{fontSize: 20, lineHeight: '24px'}}>
                                Mint Your SparkleMuffinz
                            </h1>
                            <p className='mb-5' style={{fontSize: 16}}>
                                Minting is now live! We're so excited to welcome Muffin holders to
                                the fam. Thank you all so much for the support. If you have any questions about the
                                mint, or
                                just want to show off your new Muffinz, head on over to the Discord! The journey has
                                only just begun.
                            </p>
                            <div className='contract-wrapper-mobile'>
                                <a className='contract-container mb-2' style={{color: "white", marginLeft: 20}}
                                   target={"_blank"}
                                   href={"https://etherscan.io/address/0x9c9b2e9285290536488f55b98f26226fd6851475"}>
                                    <SvgLink className='mr-12'/>
                                    <div className='ml-1'>
                                        <p className=' mb-0 mt-0' style={{fontSize: this.props.mobile ? 12 : 22}}>
                                            Contract Address:
                                        </p>
                                        <p className={this.props.mobile ? 'm-0' : 'm-0'} style={{fontSize: this.props.mobile && 14}}>0x9c...1475</p>
                                    </div>
                                </a>
                            </div>
                            <div className='row-ae w-100'>
                                <div className='mr-3 mb-2'>
                                    <p className='biglabel' style={{fontSize: 40}}>8,888</p>
                                    <p className='bigsublabel mt-0 mb-0' style={{fontSize: 28}}>Total</p>
                                </div>
                                <div className='mr-5'>
                                    <h4 className='press-start mb-1' style={{fontSize: 16}}>0.069 ETH</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Mint Price</p>
                                </div>
                            </div>
                            <div className='row-ac w-100'>
                            </div>
                            <div className='row-ac mt-3 w-100 mb-3'>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>{this.state.numMinted}</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Minted</p>
                                </div>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>{8888 - this.state.numMinted}</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Left</p>
                                </div>
                            </div>
                            <MintButton mobile={this.props.mobile} setWLRemaining={this.changeNumWhiteListed} setMintedSupply={this.setMintedSupply}/>
                            <div onClick={this.props.closeMint} className='mt-2 mint-close-mobile'>
                                Close
                            </div>
                        </div>
                    </Collapse>
                    <div className='row-ac-jb mt-1'>
                        <div className='pointer mb-1' onClick={() => this.props.scrollTo('top')}>
                            SparkleMuffinz
                        </div>
                        <div className='row-plain mb-05'>
                            <div className='connectwallet-mobile mint-here pulse' onClick={this.props.openMint}>
                                <div className='linear-wipe' style={{fontSize: 15}}>
                                    Mint Here!
                                </div>
                            </div>
                            <div className='relative mt-05' style={{height: 40, width: 40}}
                                 onClick={() => this.setState({mobileNavOpen: !this.state.mobileNavOpen})}>
                                <div className='absolute'
                                     style={{
                                         top: (0 - 35),
                                         fontSize: 50,
                                         left: 10,
                                         transition: '100ms ease',
                                         opacity: this.state.mobileNavOpen && 0,
                                     }}>
                                    _
                                </div>
                                <div className='absolute'
                                     style={{
                                         top: this.state.mobileNavOpen ? -22 : -27,
                                         fontSize: 50,
                                         left: this.state.mobileNavOpen ? -1 : 10,
                                         transition: '100ms ease',
                                         transform: this.state.mobileNavOpen && 'rotate(-45deg)',
                                         textShadow: this.state.mobileNavOpen && 'none'
                                     }}>
                                    _
                                </div>
                                <div className='absolute'
                                     style={{
                                         top: this.state.mobileNavOpen ? -22 : -19,
                                         fontSize: 50,
                                         left: this.state.mobileNavOpen ? 24 : 10,
                                         transition: '100ms ease',
                                         transform: this.state.mobileNavOpen && 'rotate(45deg)',
                                         textShadow: this.state.mobileNavOpen && 'none'
                                     }}>
                                    _
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul
                        className='mobile-navlist'
                        style={{listStyle: 'none', display: !this.state.mobileNavOpen && 'none'}}>
                        {ROUTES.map(route =>
                            <li className='m-0 p-0 mt-2 mb-2' onClick={() => {
                                this.props.scrollTo(route.ref);
                                this.setState({mobileNavOpen: false})
                            }}>
                                {route.name}
                            </li>
                        )}
                    </ul>
                </div>
            )
        } else return (
            <div
                onMouseEnter={this.hoverNav}
                onMouseLeave={() => this.setState({hoveringNav: false})}
                style={{
                    height: ((this.props.scrollPosition <= 100) || this.state.hoveringNav) ? 62 : 40,
                    borderBottom: this.props.scrollPosition <= 700 ? '3px solid #003459' : '3px solid #492726',
                }}
                className='header'
            >
                <div className='mint-page' style={{
                    height: this.props.mintPressed ? '100vh' : 0,
                    opacity: !this.props.mintPressed && 0
                }}>
                    <div className='w-70'>
                        <div className='mint-close' onClick={this.props.closeMint}>
                            CLOSE
                        </div>
                        <h1>
                            Mint Your SparkleMuffinz
                        </h1>
                        <p className='mb-5'>
                            Minting is now live! We're so excited to welcome Muffin holders to the
                            fam. Thank you all so much for the support. If you have any questions about the mint, or
                            just want to show off your new Muffinz, head on over to the Discord! The journey has only
                            just begun.
                        </p>
                        <div className='contract-wrapper'>
                            <a className='contract-container mb-2' style={{color: "white", marginLeft: 20}}
                               target={"_blank"}
                               href={"https://etherscan.io/address/0x9c9b2e9285290536488f55b98f26226fd6851475"}>
                                <SvgLink className='mr-12'/>
                                <div className='ml-1'>
                                    <p className=' mb-0 mt-0' style={{fontSize: this.props.mobile ? 12 : 16}}>
                                        Contract Address:
                                    </p>
                                    <p className={this.props.mobile ? 'm-0' : 'm-0'}>0x9c...1475</p>
                                </div>
                            </a>
                        </div>
                        <div className='row-plain'>
                            <div style={{flex: .5}}>
                                <div>
                                    <p className='biglabel'>8,888</p>
                                    <p className='bigsublabel mt-0 mb-0'>Total</p>
                                </div>
                                <div className='row-ac mt-3'>
                                    <div className='mr-5'>
                                        <h4>{this.state.numMinted}</h4>
                                        <p className='mt-0'>Minted</p>
                                    </div>
                                    <div className='mr-5'>
                                        <h4>{8888 - this.state.numMinted}</h4>
                                        <p className='mt-0'>Left</p>
                                    </div>
                                </div>
                                <div>
                                    <ProgressBar pctComplete={(this.state.numMinted / 8888) * 100}/>
                                    <div className='mt-2'>
                                        {this.props.title}.{".".repeat(this.state.numDots)}
                                    </div>
                                </div>
                            </div>
                            <div className='col-ac-jc' style={{flex: 1}}>
                                <div className='w-60'>
                                    <div className='row-plain'>
                                        <div>
                                            <h4 className='press-start mint-price'>0.069<span
                                                style={{fontSize: 16}}> ETH</span></h4>
                                            <p className='mt-2'>Mint Price</p>
                                        </div>
                                    </div>
                                    <MintButton mobile={this.props.mobile} setWLRemaining={this.changeNumWhiteListed} setMintedSupply={this.setMintedSupply}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='navtitle pointer' onClick={() => this.props.scrollTo('top')}>
                    SparkleMuffinz
                </div>
                <div className='row-ac'>
                    <ul className='nav mr-10 relative'
                        style={{zIndex: 3, opacity: this.props.mintPressed && 0}}>
                        {ROUTES.map(route =>
                            <li onClick={() => this.props.scrollTo(route.ref)}>
                                {route.name}
                            </li>
                        )}
                    </ul>
                    {/*<li style={{listStyle: 'none', position: 'relative', zIndex: 999999}} className='mr-6'>*/}
                    {/*    <Link to='/pantry' style={{textDecoration: 'none', color: 'white'}}>*/}
                    {/*        The Pantry*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <div className='connectwallet mint-here pulse' onClick={this.props.openMint}>
                        <div className='linear-wipe pulse'>
                            Mint Here!
                        </div>
                    </div>
                    <div className='row-ac'>
                        <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                           style={{textDecoration: 'none', color: 'white'}}>
                            <FontAwesomeIcon icon={faDiscord} className='mr-2 pointer discord-icon'/>
                        </a>
                        <a href='https://twitter.com/muffinznft' target='_blank'
                           style={{textDecoration: 'none', color: 'white'}}>
                            <FontAwesomeIcon icon={faTwitter} className='pointer twitter-icon'/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
