import React, { Component } from "react";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import MuffinModal from "./MuffinModal";
import ConnectWalletModal from "./ConnectWalletModal";
import SvgLink from "../icons/Link";
import { MerkleTree } from 'merkletreejs';
import keccak256 from "keccak256";
import { solidityKeccak256 } from "ethers/lib/utils";
import { whitelist } from "../utils/whitelist";
import { ethers } from "ethers"
import { ABI, CONTRACT_ADDRESS, MINT_PRICE_WEI, PUBLIC_MINT_FUNCTION_SELECTOR, PRESALE_MINT_FUNCTION_SELECTOR, MAINNET_CHAIN_ID } from "../utils/consts";
import MintSuccessModal from "./MintSuccessModal";


class MintButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: "",
            connected: false,
            mode: "walletconnect",
            connectModalVisble: false,
            numToMint: 1,
            wlRemaining: 0,
            mintSuccess: false
        }
        this.provider = new WalletConnectProvider({
            infuraId: "27e484dcd9e3efcfd25a83a78777cdf1", // Required
        });
        this.ethersProvider = undefined
        this.contract = new ethers.Contract(CONTRACT_ADDRESS, ABI)
        this.merkleTree = this.genMerkleTree()
    }

    genMerkleTree = () => {
        const elements = Object.keys(whitelist).map(key => {
            return solidityKeccak256(["address", "uint256"], [key, whitelist[key]])
        })
        return new MerkleTree(elements, keccak256, { sortPairs: true })
    }

    triggerProvider = async (mode) => {
        if (mode === "metamask") {
            if (window.ethereum) {
                this.provider = window.ethereum;
                const address = await this.provider.request({ method: 'eth_requestAccounts' })
                this.setState({
                    connected: true,
                    mode: "ethereum",
                    walletAddress: ethers.utils.getAddress(address[0].toString())
                })
                // METAMASK CHECK CONNECTION
            } else {
                alert("No Metamask connection found! Please use wallet connect.")
            }
        } else {
            try {
                const connect = await this.provider.enable();
                this.setState({
                    connected: true,
                    walletAddress: ethers.utils.getAddress(connect[0].toString())
                })

            } catch (err) {
                console.log("ERROR", err)
            }
        }

        const chainId = await this.provider.request({ method: "eth_chainId" })
        if (chainId !== MAINNET_CHAIN_ID) {
            await this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: MAINNET_CHAIN_ID }]
            });
        }

        // init ethers provider using the web3 provider we just connected to
        this.ethersProvider = new ethers.providers.Web3Provider(this.provider)
        this.getWhitelistRemaining()
    }

    onSuccess = () => {
        this.props.successMint();
    }

    getWhitelistRemaining = async () => {
        const wlRemaining = whitelist[this.state.walletAddress] ? whitelist[this.state.walletAddress] : 0;
        this.setState({ wlRemaining })
        this.props.setWLRemaining(wlRemaining)
        return wlRemaining
    }

    presaleMintNow = async () => {
        // check whitelist status
        const allowedMintAmount = whitelist[this.state.walletAddress]
        if (allowedMintAmount === undefined) {
            alert("Oops! You're not on the whitelist")
            return
        }
        if (this.state.numToMint > allowedMintAmount) {
            alert("You are attempting to mint too many!")
            return
        }
        const leaf = solidityKeccak256(["address", "uint256"], [this.state.walletAddress, allowedMintAmount])
        const proof = this.merkleTree.getHexProof(leaf)
        console.log("LEAF", leaf)
        console.log("PROOF", proof)

        // send tx
        const encodedParams = ethers.utils.defaultAbiCoder.encode(
            ["uint256", "uint256", "bytes32", "bytes32[]"],
            [this.state.numToMint, allowedMintAmount, leaf, proof]
        )
        const calldata = PRESALE_MINT_FUNCTION_SELECTOR + encodedParams.slice(2)
        const txHash = await this.provider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.state.walletAddress,
                to: CONTRACT_ADDRESS,
                value: '0x' + ((MINT_PRICE_WEI) * this.state.numToMint).toString(16),
                data: calldata,
            }]
        })
        await this.ethersProvider.waitForTransaction(txHash)
        this.setState({ mintSuccess: true });
        console.log("MINED")
    }

    publicMintNow = async () => {
        const encodedParams = ethers.utils.defaultAbiCoder.encode(
            ["uint256"], [this.state.numToMint]
        )
        const calldata = PUBLIC_MINT_FUNCTION_SELECTOR + encodedParams.slice(2)
        const txHash = await this.provider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.state.walletAddress,
                to: CONTRACT_ADDRESS,
                value: '0x' + ((MINT_PRICE_WEI) * this.state.numToMint).toString(16),
                data: calldata,
            }]
        })
        await this.ethersProvider.waitForTransaction(txHash)
        console.log("MINED")
    }

    counterUp = () => {
        this.setState({ numToMint: this.state.numToMint + 1 })
    }
    counterDown = () => {
        this.setState({ numToMint: this.state.numToMint - 1 })
    }

    render() {
        const minusDisabled = this.state.numToMint === 1
        if (!this.state.connected) {
            return <>
                <button
                    className='mint-button'
                    onClick={() => this.setState({ connectModalVisible: true })}
                >Connect Wallet
                </button>
                <ConnectWalletModal
                    isOpen={this.state.connectModalVisible}
                    toggle={() => this.setState({ connectModalVisible: false })}
                    triggerProvider={this.triggerProvider}
                />
            </>
        } else {
            return (
                <div>
                    <div className='row-ac'>
                        <div className=''>
                            <div className='shelf-stat-label'>
                                Number to Mint
                            </div>
                            <div className='press-start mt-1 mb-3' style={{ fontSize: 24 }}>
                                {this.state.numToMint}
                            </div>
                        </div>
                        <div className='row-ac press-start' style={{ fontSize: 30 }}>
                            <div className='counter-button' onClick={!minusDisabled && this.counterDown}
                                style={{ opacity: minusDisabled && .5 }}>
                                -
                            </div>
                            <div className='counter-button' onClick={this.counterUp}>
                                +
                            </div>
                        </div>
                    </div>
                    <button className='mint-button' onClick={this.publicMintNow}>Mint
                        Now {parseFloat((this.state.numToMint * .069).toFixed(3))} ETH
                    </button>
                    <div className='mt-2' style={{ fontSize: 16 }}>Wallet Address : {this.state.walletAddress}</div>
                    <MintSuccessModal
                    isOpen={this.state.mintSuccess}
                    mobile={this.props.mobile}
                    toggle={() => this.setState({ mintSuccess: false })}
                />
                </div>
            )
        }
    }
}


export default MintButton
