import './Formatting.css'
import './App.css';
import React, {Component} from "react";
import Navbar from "./Navbar";
import Team from "./Team";
import FAQ from "./FAQ";
import Footer from "./Footer";
import SplashSection from "./SplashSection";
import SplashSection2 from "./SplashSection2";
import LockedSection from "./LockedSection";
import SplashSectionMobile from "./SplashSectionMobile";
import SplashSection2Mobile from "./SplashSection2Mobile";
import LockedSectionMobile from "./LockedSectionMobile";
import FAQMobile from "./FAQMobile";
import TeamMobile from "./TeamMobile";
import TabletSplash from "./TabletSplash";
import RoadmapMobile from "./RoadmapMobile";
import {LOCK_SPLASH, mintTime} from "./copy";
import BenefitsMobile from "./BenefitsMobile";
import Benefits from "./Benefits";

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scrollPosition: 0,
            bodyHeight: '100vh',
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth),
            timeToMint: {},
            mintPressed: false
        }
        this.topRef = React.createRef();
        this.footerRef = React.createRef();
        this.meetRef = React.createRef();
        this.roadmapRef = React.createRef();
        this.teamRef = React.createRef();
        this.faqRef = React.createRef();
        this.benefitsRef = React.createRef();
    }

    componentDidMount() {
        console.log("If you're reading this, just know that we love you... and now, go repeat this secret message back to chocolate chip muffin: the muffinz bake at dawn. He will know what to do!")
        if (!this.state.tablet) {
            window.addEventListener('scroll', this.listenToScroll)
            window.addEventListener("resize", this.resize.bind(this));
        }
        this.resize();
        setInterval(() =>
                this.setState({timeToMint: this.secondsToTime()})
            , 1000)
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth)
        });
    }

    componentWillUnmount() {
        if (!this.state.tablet) {
            window.removeEventListener('scroll', this.listenToScroll)
            window.removeEventListener("resize", this.resize.bind(this));
        }
    }

    secondsToTime() {
        const currentTime = new Date();

        const secs = Math.floor((mintTime - currentTime) / 1000);

        const days = Math.floor(secs / (60 * 60 * 24));

        let divisor_for_hours = Math.floor(secs / (60 * 60 * 24));
        let hours = Math.floor(divisor_for_hours / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "d": days,
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    listenToScroll = () => {
        const viewPortHeight = window.innerHeight

        // if (this.state.scrollPosition + viewPortHeight >= this.state.bodyHeight) {
        //     // html.style.overflow = 'hidden'
        //     window.scroll({top: this.state.bodyHeight - viewPortHeight, behavior: 'smooth'})
        // }

        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height
        // console.log(scrolled, winScroll, height)
        this.setState({
            scrollPosition: winScroll,
        })

        const footerHeight = this.footerRef.current.offsetTop
        this.setState({bodyHeight: footerHeight + 80})
        // console.log(this.state.bodyHeight, this.state.scrollPosition + viewPortHeight)
    }

    scrollTo = (route) => {
        if (route === 'top') {
            this.topRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'about') {
            this.meetRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'roadmap') {
            this.roadmapRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'team') {
            this.teamRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'faq') {
            this.faqRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'benefits') {
            this.benefitsRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }

    render() {
        const timeLeft = this.state.timeToMint;
        const timeLeftString = timeLeft.d + " days"
        if (this.state.tablet) {
            return (
                <TabletSplash/>
            )
        }
        return (
            <div ref={this.topRef} style={{height: this.state.bodyHeight}}>
                <Navbar
                    mobile={this.state.mobile}
                    scrollPosition={this.state.scrollPosition}
                    scrollTo={this.scrollTo}
                    mintPressed={this.state.mintPressed}
                    openMint={() =>
                        this.setState({mintPressed: true})
                    }
                    closeMint={() => this.setState({mintPressed: false})}
                />
                {this.state.mobile ?
                    <SplashSectionMobile
                        openMint={()=>this.setState({mintPressed: true})}
                        scrollPosition={this.state.scrollPosition}/>
                    :
                    <SplashSection
                        openMint={()=>this.setState({mintPressed: true})}
                        scrollPosition={this.state.scrollPosition}/>
                }
                {this.state.mobile ?
                    <div ref={this.meetRef}>
                        <SplashSection2Mobile scrollPosition={this.state.scrollPosition}/>
                    </div>
                    :
                    <div ref={this.meetRef}>
                        <SplashSection2
                            offset={this.meetRef.current?.offsetTop}
                            scrollPosition={this.state.scrollPosition}/>
                    </div>
                }
                {this.state.mobile ?
                    <div ref={this.roadmapRef}>
                        <LockedSectionMobile
                            title={LOCK_SPLASH[0].title}
                            desc={LOCK_SPLASH[0].desc}
                            scrollPosition={this.state.scrollPosition}
                        />
                    </div>
                    :
                    <div ref={this.roadmapRef}>
                        <LockedSection
                            title={LOCK_SPLASH[0].title}
                            desc={LOCK_SPLASH[0].desc}
                            scrollPosition={this.state.scrollPosition}
                        />
                    </div>
                }
                {this.state.mobile &&
                <RoadmapMobile progressLabel={LOCK_SPLASH[0].title}/>
                }
                {this.state.mobile ?
                    <div ref={this.benefitsRef}>
                        <BenefitsMobile scrollPosition={this.state.scrollPosition}/>
                    </div>
                    :
                    <div ref={this.benefitsRef}>
                        <Benefits scrollPosition={this.state.scrollPosition}/>
                    </div>
                }
                {this.state.mobile ?
                    <div ref={this.teamRef}>
                        <TeamMobile scrollPosition={this.state.scrollPosition}/>
                    </div>
                    :
                    <div ref={this.teamRef}>
                        <Team scrollPosition={this.state.scrollPosition}/>
                    </div>
                }

                {this.state.mobile ?
                    <div ref={this.faqRef}>
                        <FAQMobile
                            scrollPosition={this.state.scrollPosition}/>
                    </div>
                    :
                    <div ref={this.faqRef}>
                        <FAQ
                            scrollPosition={this.state.scrollPosition}/>
                    </div>
                }
                <div ref={this.footerRef}>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Main;