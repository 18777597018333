import React, {Component} from "react";
import Marquee from "react-fast-marquee";
import cloud1 from "./img/cookie-scene/cloud1.png";
import cloud4 from "./img/cookie-scene/cloud4.png";
import cloud3 from "./img/cookie-scene/cloud3.png";
import cloud2 from "./img/cookie-scene/cloud2.png";
import muffin1 from "./img/misc/muffin1.GIF";
import muffin2 from "./img/misc/muffin2.GIF";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";

export default class TabletSplash extends Component {
    render() {
        return (
            <div>
                <div style={{position: 'relative', overflow: 'hidden'}}>
                    <div className='splashsectioncointainer'
                         style={{minHeight: '100vh', flexDirection: "column", alignItems: 'center'}}>
                        <div className='cloud1'>
                            <Marquee gradient={false} speed={35}>
                                <img src={cloud1} className='cloudimage'/>
                            </Marquee>
                        </div>
                        <div className='cloud4'>
                            <Marquee gradient={false} speed={30}>
                                <img src={cloud4} className='cloudimagesm'/>
                            </Marquee>
                        </div>
                        <div className='cloud2'>
                            <Marquee gradient={false}>
                                <img src={cloud3} className='cloudimage'/>
                            </Marquee>
                        </div>
                        <div className='cloud3'>
                            <Marquee gradient={false} speed={15}>
                                <img src={cloud2} className='cloudimage'/>
                            </Marquee>
                        </div>
                        {/*<img src={goats} className='absolute goats'/>*/}
                        <div className='splashcard mb-10'
                             style={{transform: `translateY(${this.props.scrollPosition * .35}px`, marginTop: '14vh'}}>
                            <div style={{marginBottom: -32, position: 'relative'}}>
                                <img src={muffin1} className=''
                                     style={{width: 75, position: 'absolute', bottom: -30, right: -244}}/>
                                <img src={muffin2} className='' style={{
                                    width: 75,
                                    transform: 'scaleX(-1)',
                                    position: 'absolute',
                                    bottom: -106,
                                    left: -146
                                }}/>
                            </div>
                            <h1 style={{fontSize: 70, lineHeight: '75px'}} className='mt-5'>Sparkle Muffinz</h1>
                            <p>
                                A collection of <span style={{fontSize: 30}}>8,888</span> unique NFTs crawling <br/> on
                                the
                                Ethereum blockchain
                            </p>
                            <div className='row-ac'>
                                {/*<button className="btn second">Button</button>*/}
                                <div className='relative'>
                                    <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                                       style={{textDecoration: 'none', color: 'white'}}>
                                        <button className='row-ac mr-4 discord pointer'>
                                            <FontAwesomeIcon icon={faDiscord} className='mr-2'/>
                                            Discord
                                        </button>
                                    </a>
                                </div>
                                <a href='https://twitter.com/muffinznft' target='_blank'
                                   style={{textDecoration: 'none', color: 'white'}}>
                                    <button className='row-ac twitter pointer'>
                                        <FontAwesomeIcon icon={faTwitter} className='mr-2'/>
                                        <div>
                                            Twitter
                                        </div>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className='roadmapcard ml-6 mr-6'>
                            Hey there tablet using goober! View the site on your phone or computer to get the full
                            experience. <br/><br/> - The SparkleMuffinz Team
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
