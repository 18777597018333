import React, {Component} from "react";
import muffin2 from '../img/muffins/muffin5.GIF';
import PantryNavbar from "../PantryNavbar";
import Footer from "../Footer";
import {CYPHER, QUIZ} from "../copy";
import QuizQuestion from "./QuizQuestion";
import muffin1 from "../img/misc/muffin1.GIF";


class QuizPage extends Component {
    state = {
        scrollPosition: 0,
        mobile: window.innerWidth <= 760,
        tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth),
        correctAnswers: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false
        }
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        this.setState({
            scrollPosition: winScroll,
        })

    }


    componentDidMount() {
        if (!this.state.tablet) {
            window.addEventListener('scroll', this.listenToScroll)
            window.addEventListener("resize", this.resize.bind(this));
        }
        this.resize();
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth)
        });
    }

    componentWillUnmount() {
        if (!this.state.tablet) {
            window.removeEventListener('scroll', this.listenToScroll)
            window.removeEventListener("resize", this.resize.bind(this));
        }
    }

    checkAnswer = (i, answer, answers) => {
        const lowerCase = answer.toLowerCase();
        const correctAnswers = this.state.correctAnswers;
        const correct = answers.includes(lowerCase);
        if (correct) {
            correctAnswers[i] = true;
        }
        this.setState({correctAnswers})
    }


    render() {
        console.log("HERE", this.state.correctAnswers)
        return (
            <div>
                <PantryNavbar
                    mobile={this.state.mobile}
                    scrollPosition={this.state.scrollPosition}
                />
                <div className='col-ac-jc pl-10 pr-10 pb-10 mb-10'>
                    <div className='splashcard mb-0 text-center' style={{marginTop: '14vh'}}>
                        <div style={{marginBottom: -32, position: 'relative'}}>
                            <img src={muffin1} className=''
                                 style={{width: 75, position: 'absolute', bottom: -30, right: -244}}/>
                            <img src={muffin2} className='' style={{
                                width: 75,
                                transform: 'scaleX(-1)',
                                position: 'absolute',
                                bottom: -106,
                                left: -146
                            }}/>
                        </div>
                        <h1 style={{fontSize: 70, lineHeight: '75px'}} className='mt-5'>Sparkle Muffinz</h1>
                    </div>
                    <h3 className='mb-2' style={{fontSize: 80, lineHeight: '20px'}}>
                        Scavenger Hunt
                    </h3>
                    <p className='mb-2 text-center w-70'>
                        Looks like you’ve fallen into our Muffinz web! See if you can find your way to our secret second
                        Discord by unraveling the clues below. <br/><br/>First person to find the hidden Discord gets 1 free
                        SparkleMuffinz NFT. Second person to find it gets 0.1 ETH. <br/><br/>
                        <strong>First 20 get Glazed (4 WL spots)! Next 100 get Baked (2 WL spots)!</strong>
                    </p>
                    <div
                        className='row-ac-jc mt-4 mb-4 w-70 cypher-overview : join this link to complete the hunt : https://discord.gg/uEBKPwDg '
                        style={{flexWrap: 'wrap'}}>
                        {CYPHER.map((piece, i) => {
                                if (!this.state.correctAnswers[i]) {
                                    return (
                                        <div key={piece} className='ml-1 mr-1 row-ac-jc' style={{width: 150, height: 60}}>
                                            {"----------"}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='ml-1 mr-1 row-ac-jc'
                                             style={{width: 150, height: 60, backgroundColor: "#FFFFFF20", color: 'black'}}>
                                            {piece[0]}
                                        </div>
                                    )
                                }
                            }
                        )}
                    </div>
                    <div className='quiz-container w-70 p-4'>
                        {QUIZ.map((question, i) =>
                            <QuizQuestion i={i} key={question.question} question={question.question}
                                          answers={question.answers} checkAnswer={this.checkAnswer}/>
                        )}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default QuizPage;
