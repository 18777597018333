import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import metamask from '../img/misc/MetaMask_Fox.svg.png'
import shadow from '../img/misc/shadow.png'

class MintSuccessModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.toggle}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x' onClick={this.props.toggle}>
                        X
                    </div>
                    <div className='col-ac'>
                        <div className='modal-main-stats'>
                            <div className='ml-3 mr-3'>
                                <div>
                                    Mint Success!
                                </div>
                            </div>
                        </div>
                        <div className='col-plain' style={{width:this.props.mobile ? 300 : 500}}>
                            <div className='wallet-option'>
                                <div className='relative'>
                                    <img src={shadow} style={{width: 150, height: 150}} className='mt-1'/>
                                    <div className='absolute question-mark'>
                                        ?
                                    </div>
                                </div>
                                <p className='mb-1 mt-1'>Congratulations! You're now a proud member of the
                                    SparkleMuffinz fam! Join us in the Discord to show off your new Muffins!</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default MintSuccessModal
