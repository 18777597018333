import './Formatting.css'
import './App.css';
import {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons'

class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='row-ac mr-2'>
                    <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                       style={{textDecoration: 'none', color: 'white'}}>
                        <FontAwesomeIcon icon={faDiscord} className='mr-2 pointer discord-icon'/>
                    </a>
                    <a href='https://twitter.com/muffinznft' target='_blank'
                       style={{textDecoration: 'none', color: 'white'}}>
                        <FontAwesomeIcon icon={faTwitter} className='pointer twitter-icon'/>
                    </a>
                </div>
                <div>
                    <div>
                        SparkleMuffinz
                    </div>
                    <div style={{fontSize: 16}}>
                        © 2021
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
