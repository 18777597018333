import {Component} from "react";
import {Collapse} from "react-collapse/lib/Collapse";
import chevron from '../img/misc/chevron.png'

class AnswerCollapse extends Component {
    state = {
        isOpened: false
    }

    render() {
        return (
            <div className='faqlistitem w-100' style={{border: this.props.last && 'none'}}>
                <div className='row-ac-jb' onClick={() => this.setState({isOpened: !this.state.isOpened})}>
                    <h3 style={{fontSize:this.props.mobile && 20}} className='mr-1'><span className='yellow'>Q:</span> {this.props.question}</h3>
                    <img className='chevron' src={chevron}
                         style={{transform: this.state.isOpened ? 'rotate(180deg)' : 'rotate(0deg'}}/>
                </div>
                <div className='answerslist'>
                    <Collapse isOpened={this.state.isOpened}>
                        <div className='answer'>
                            <p className='m-0' style={{fontSize:this.props.mobile && 18}}>
                                <span style={{fontWeight:700, color:'grey'}}>A:</span> {this.props.answer}
                            </p>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default AnswerCollapse;
