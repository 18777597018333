import React, {Component} from "react";

class ProgressBar extends Component {
    render() {
        return (
            <div className='row-ac mt-4'>
                <div className='progress-vborder'/>
                <div className='w-100'>
                    <div className='progress-hborder'/>
                    <div className='progressbar-wrapper' style={{width:this.props.pctComplete + '%'}}>
                        <div className='progressbar'>
                        </div>
                        <div className='highlight'/>
                    </div>
                    <div className='progress-hborder'/>
                </div>
                <div className='progress-vborder'/>
            </div>
        )
    }
}

export default ProgressBar
