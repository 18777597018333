import * as React from "react";

function SvgLink(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.143 12.857V18a1.714 1.714 0 01-1.714 1.714H6A1.714 1.714 0 014.286 18V8.571A1.714 1.714 0 016 6.857h5.143M14.571 4.286h5.143v5.143M10.286 13.714l9.428-9.428"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgLink;
