import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";

class MuffinModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggleMuffinModal}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}} className='modal-body relative'>
                    <div className='close-modal-x' onClick={this.props.toggleMuffinModal}>
                        X
                    </div>
                    <h3 className='mb-3 row-ac-jc mt-0'>{this.props.selectedMuffin.name}</h3>
                    <div className='row-ac-jc'>
                        <img src={this.props.selectedMuffin.image} className='modal-muffin'/>
                    </div>
                    <div className='row-ac-jc'>
                        <div className='modal-main-stats'>
                            <div className='ml-3 mr-3'>
                                <div>
                                    Evolution
                                </div>
                                <div>
                                    {this.props.selectedMuffin.evolution}
                                </div>
                            </div>
                            <div className='ml-3 mr-3'>
                                <div>
                                    Rarity
                                </div>
                                <div>
                                    {this.props.selectedMuffin.rarity}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='muffin-stat-row mt-4' style={{maxWidth: 500}}>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Muffin
                            </div>
                            <div>
                                {this.props.selectedMuffin.muffin}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Eye Style
                            </div>
                            <div>
                                {this.props.selectedMuffin.eye_style}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Eye Color
                            </div>
                            <div>
                                {this.props.selectedMuffin.eye_color}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Mouth
                            </div>
                            <div>
                                {this.props.selectedMuffin.mouth}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Body Color
                            </div>
                            <div>
                                {this.props.selectedMuffin.body_color}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Head Accessory
                            </div>
                            <div>
                                {this.props.selectedMuffin.head_extra}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Hand Accessory
                            </div>
                            <div>
                                {this.props.selectedMuffin.hand_extra}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Arm Position
                            </div>
                            <div>
                                {this.props.selectedMuffin.hand_extra}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Leg Position
                            </div>
                            <div>
                                {this.props.selectedMuffin.leg_position}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Eye Position
                            </div>
                            <div>
                                {this.props.selectedMuffin.eye_position}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Mouth Position
                            </div>
                            <div>
                                {this.props.selectedMuffin.mouth_position}
                            </div>
                        </div>
                        <div className='modal-stat'>
                            <div className='shelf-stat-label'>
                                Background
                            </div>
                            <div>
                                {this.props.selectedMuffin.background}
                            </div>
                        </div>
                    </div>
                    <div className='row-ac mt-4'>
                        <button className='ml-a'>
                            Unstake
                        </button>
                        <button className='ml-3' onClick={this.props.toggleMuffinModal}>Close</button>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default MuffinModal
