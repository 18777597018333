import './Formatting.css'
import './App.css';
import {Component} from "react";
import AnswerCollapse from "./components/AnswerCollapse";
import divider from "./img/misc/mobileborder.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {DISCLAIMER, QADATA} from "./copy";

class FAQMobile extends Component {
    render() {
        return (
            <div style={{position: 'relative'}} className='splash2bg2'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <div className='faqcontainer p-4'>
                    <h2 className='pb-2 mt-10'>FAQ</h2>
                    {QADATA.map((faq, i) =>
                        <AnswerCollapse
                            mobile
                            last={i === (QADATA.length - 1)} key={faq.question} question={faq.question}
                            answer={faq.answer}/>
                    )}
                    <div className='mt-10 mb-10'>
                        <h2 className='pb-2' style={{fontSize: 24}}>Stay up to date!</h2>
                        <p style={{fontSize: 18}}>
                            Join us on Discord or follow our Twitter account to get all the latest updates.
                        </p>
                        <div className='row-ac mb-4'>
                            {/*<button className="btn second">Button</button>*/}
                            <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                               style={{textDecoration: 'none', color: 'white'}}>
                                <div className='relative'>
                                    <button className='row-ac mr-4 discord pointer'>
                                        <FontAwesomeIcon icon={faDiscord} className='mr-2'/>
                                        Discord
                                    </button>
                                </div>
                            </a>
                            <a href='https://twitter.com/muffinznft' target='_blank'
                               style={{textDecoration: 'none', color: 'white'}}>
                                <button className='row-ac twitter pointer'>
                                    <FontAwesomeIcon icon={faTwitter} className='mr-2'/>
                                    <div>
                                        Twitter
                                    </div>
                                </button>
                            </a>
                        </div>
                        <p className='mt-6' style={{fontSize:12}}>{DISCLAIMER}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQMobile;
