import './Formatting.css'
import './App.css';
import {Component} from "react";
import AnswerCollapse from "./components/AnswerCollapse";
import divider from "./img/misc/divider.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {QADATA, DISCLAIMER} from "./copy";

class FAQ extends Component {
    render() {
        return (
            <div style={{position: 'relative'}} className='splash2bg'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <div className='faqcontainer'>
                    <div style={{width: '60%', marginTop: 140}}>
                        <h2 className='pb-2'>FAQ</h2>
                        {QADATA.map((faq, i) =>
                            <AnswerCollapse last={i === (QADATA.length - 1)} key={faq.question} question={faq.question}
                                            answer={faq.answer}/>
                        )}
                    </div>
                    <div style={{width: '60%'}} className='mt-10 mb-10'>
                        <h2 className='pb-2'>Stay up to date!</h2>
                        <p>
                            Join us on Discord or follow our Twitter account to get all the latest updates.
                        </p>
                        <div className='row-ac'>
                            <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                               style={{textDecoration: 'none', color: 'white'}}>
                                <div className='relative'>
                                    <button className='row-ac mr-4 discord pointer'>
                                        <FontAwesomeIcon icon={faDiscord} className='mr-2'/>
                                        Discord
                                    </button>
                                </div>
                            </a>
                            <a href='https://twitter.com/muffinznft' target='_blank'
                               style={{textDecoration: 'none', color: 'white'}}>
                                <button className='row-ac twitter pointer'>
                                    <FontAwesomeIcon icon={faTwitter} className='mr-2'/>
                                    <div>
                                        Twitter
                                    </div>
                                </button>
                            </a>
                        </div>
                    <p className='mt-6' style={{fontSize:16}}>{DISCLAIMER}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ;
