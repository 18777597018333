import {Component} from "react";
import divider from "./img/misc/divider.png";
import {HOLDER_BENEFITS, BENEFITS_BLURB} from "./copy";
import paperplane from "./img/misc/umbrella.png";
import bubblemuffin from "./img/misc/bubblemuffin.png";

class Benefits extends Component {
    render() {
        return (
            <div style={{position: 'relative', minHeight: 1200}} className='splash2bg'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <div style={{position: 'relative', overflow: 'hidden', padding: "10% 10%", minHeight:800, width:'50%'}}>
                    <div style={{transform: `translateY(${(-3900 + this.props.scrollPosition) * .2}px`}}>
                        <h2>Muffinz Holder Benefits</h2>
                        <p className='mt-6'>
                            {BENEFITS_BLURB}
                        </p>
                        <ol className='pl-2'>
                            {HOLDER_BENEFITS.map((benefit, i) =>
                                <li style={{listStyle: "none"}} className='mb-1'>
                                    <span className='yellow' style={{fontWeight: '600'}}>{i + 1}.</span> {benefit}
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
                <img src={paperplane} style={{
                    position: 'absolute',
                    top: -((-4200 + this.props.scrollPosition) * .3),
                    right: '3.5vw',
                    width: 500
                }}/>
                <img src={bubblemuffin} style={{
                    position: 'absolute',
                    top: -((-6600 + this.props.scrollPosition) * .36),
                    right: '18.5vw',
                    width: 400
                }}/>
            </div>
        )
    }
}

export default Benefits
