import './Formatting.css'
import './App.css';
import {Component} from "react";
import divider from "./img/misc/mobileborder.png";
import bgwires from "./img/wires-scene/bgwires-mobile.png";
import powerlinesmobile1 from './img/wires-scene/powerlinemobile1.png'
import powerlinesmobile2 from './img/wires-scene/powerlinemobile2.png'
import powerlinesmobile3 from './img/wires-scene/powerlinemobile3.png'

class TeamMobile extends Component {
    render() {
        return (
            <div style={{position: 'relative'}} className='teambg'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <img src={bgwires} className='w-100' style={{
                    position: 'absolute',
                    top: -(-950 + this.props.scrollPosition * .18),
                    right: 0,
                    left: 0,
                }}/>
                <div className='teamcontainer' style={{minHeight: 750}}>
                    <div className='col-ac'>
                        <div>
                            <h2 style={{fontSize: 30}} className='mt-6'>Team</h2>
                            <p style={{fontSize: 18}}>
                                New Forward Thinking (NFT) is a group who draws inspiration from the diversity of our
                                own community, with the goal of bringing on surprising change. Stay tuned to see what we
                                do next.
                            </p>
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-1300 + this.props.scrollPosition * .25),
                                right: 0,
                                left: 0,
                            }}
                        >
                            <img src={powerlinesmobile1} style={{
                                position: "relative"
                            }}
                                 className='w-100'
                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-1650 + this.props.scrollPosition * .3),
                                right: 0,
                                left: 0,
                            }}
                        >
                            <img src={powerlinesmobile3} style={{
                                position: "relative"
                            }}
                                 className='w-100'
                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: -(-2200 + this.props.scrollPosition * .4),
                                right: 0,
                                left: 0,
                            }}
                        >
                            <img src={powerlinesmobile2} style={{
                                position: "relative"
                            }}
                                 className='w-100'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamMobile;
