import './Formatting.css'
import './App.css';
import {Component} from "react";
import divider from './img/misc/mobileborder.png'
import trophy from "./img/misc/muffin2.GIF";
import medal from "./img/misc/muffin1.GIF";
import muffin from '../src/img/muffins/muffinbgcolor.JPG'
import {ATTRIBUTES, ATTRIBUTES_SUBTEXT} from "./copy";

class SplashSection2Mobile extends Component {
    render() {
        return (
            <div style={{position: 'relative'}} className='splash2bg'>
                <img src={divider} style={{position: 'absolute', top: -40, zIndex: 999}} className='w-100'/>
                <div style={{position: 'relative', overflow: 'hidden'}}>
                    <div className='p-4'>
                        <h2 style={{fontSize: 30}} className='mt-6'>Meet the Muffinz</h2>
                        <div className='row-ac mt-4'>
                            <p style={{fontSize: 18}}>
                                The SparkleMuffinz is a collection of <span
                                style={{fontSize: 32, lineHeight: '8px'}}>8,888</span> unique and upgradeable NFTs
                                crawling on the
                                Ethereum blockchain.
                                <br/><br/>
                                <strong>What makes us unique?</strong><br/>
                                Developed by big brain MIT engineers.
                                Designed by Adam the Creator.
                                Also - the Oven.
                                Want to upgrade and change your SparkleMuffin? You can.
                                <br/><br/>
                                Each Muffinz also gets you access to Daily Muffinz, our daily thoughts and
                                charts on interesting projects, performance benchmarking, and the broader NFT
                                and crypto market.
                            </p>
                            <div style={{width: 75, height: 75}}>
                                <img src={trophy} className='trophy' style={{width: 75, height: 75}}/>
                            </div>
                        </div>
                        <div className='row-ac'>
                            <img src={medal} className='medal' style={{width: 75, height: 75}}/>
                            <p style={{fontSize: 18}}>
                                The SparkleMuffinz is the first of a series of projects by New Forward Thinking (NFT), a
                                group of individuals looking to build an impactful community with staying power. We have
                                lots lined up for Muffinz hodlers including exclusive analytics tools, collaborations
                                with artists and charities, meetups and more.
                            </p>
                        </div>
                        <div className='pt-8'>
                            <div className='row-ae mb-4'>
                                <div className='hover14 shinetilesm hover-shadow' style={{marginLeft: 0}}>
                                    <figure>
                                        <img src={muffin}
                                             style={{width: 150, height: 150, marginTop: -25, marginLeft: -40}}/>
                                    </figure>
                                </div>
                                <div>
                                    <p className='biglabel' style={{fontSize: 40}}>8,888</p>
                                    <p className='bigsublabel mt-0 mb-0' style={{fontSize: 28}}>Total</p>
                                </div>
                            </div>
                        </div>
                        <div className='row-ac' style={{flexWrap: 'wrap'}}>
                            {ATTRIBUTES.map((attribute, i) =>
                                <div className='mr-3'>
                                    <h4 style={{fontSize: 24}}>{attribute.amt}</h4>
                                    <p className='mt-0' style={{fontSize: 16}}>{attribute.title}</p>
                                </div>
                            )}
                        </div>
                        <p style={{fontSize: 18}} className='mb-10'>
                            {ATTRIBUTES_SUBTEXT}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SplashSection2Mobile;
