import './Formatting.css'
import './App.css';
import React, {Component} from "react";
import Navbar from "./Navbar";
import Team from "./Team";
import FAQ from "./FAQ";
import Footer from "./Footer";
import SplashSection from "./SplashSection";
import SplashSection2 from "./SplashSection2";
import LockedSection from "./LockedSection";
import SplashSectionMobile from "./SplashSectionMobile";
import SplashSection2Mobile from "./SplashSection2Mobile";
import LockedSectionMobile from "./LockedSectionMobile";
import FAQMobile from "./FAQMobile";
import TeamMobile from "./TeamMobile";
import TabletSplash from "./TabletSplash";
import RoadmapMobile from "./RoadmapMobile";
import {LOCK_SPLASH} from "./copy";
import QuizPage from "./components/QuizPage";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/ethereum-provider";

import {
    BrowserRouter,
    Route,
    Link, Routes, Switch
} from "react-router-dom";
import Main from "./Main";
import MyMuffins from "./components/MyMuffins";
import MyMuffinsOverview from "./components/MyMuffinsOverview";

const wcOptions = {
    chainId: "0x1", // blockchain and network identifier
    logger: "debug", // use debug to show all log information on browser console
    methods: ["invokefunction"], // which RPC methods do you plan to call
    relayServer: "wss://relay.walletconnect.org", // we are using walletconnect's official relay server
    appMetadata: {
        name: "MyApplicationName", // your application name to be displayed on the wallet
        description: "My Application description", // description to be shown on the wallet
        url: "https://myapplicationdescription.app/", // url to be linked on the wallet
        icons: ["https://myapplicationdescription.app/myappicon.png"], // icon to be shown on the wallet
    }
};

class App extends Component {

    render() {
        return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Main}/>
                        {/*<Route exact path="/pantry" component={MyMuffins}/>*/}
                        {/*<Route exact path="/quiz" component={QuizPage}/>*/}
                    </Switch>
                </BrowserRouter>
        );
    }
}

export default App;
