import './Formatting.css'
import './App.css';
import {Component} from "react";
import divider from './img/misc/divider.png'
import trophy from "./img/muffins/muffin5.GIF";
import medal from "./img/muffins/muffin6.gif";
import paperplane from "./img/misc/paperplane.png";
import parachute from "./img/misc/parachute.png";
import muffin from '../src/img/muffins/muffinbgcolor.JPG'
import {ATTRIBUTES, ATTRIBUTES_SUBTEXT} from "./copy";
import hanging from './img/misc/hanging.png'

class SplashSection2 extends Component {
    render() {
        const difference = this.props.scrollPosition - this.props.offset
        return (
            <div style={{position: 'relative'}} className='splash2bg'>
                <img src={divider} style={{position: 'absolute', top: -32}} className='w-100'/>
                <div style={{position: 'relative', overflow: 'hidden'}}>
                    <div className='splashsection2cointainer' style={{width: '50%', flexWrap: 'wrap'}}>
                        <div style={{flex: 1}} className='mr-5'>
                            <div className='w-100 mb-10 mt-8'
                                 style={{
                                     marginTop: 200,
                                     transform: `translateY(${-difference * .2}px`
                                 }}>
                                <h2>Meet the Muffinz</h2>
                                <div className='row-ac mt-4'>
                                    <p>
                                        The SparkleMuffinz is a collection of <span
                                        style={{fontSize: 32}}>8,888</span> unique and upgradeable NFTs crawling on the
                                        Ethereum blockchain.
                                        <br/><br/>
                                        <strong>What makes us unique?</strong><br/>
                                        Developed by big brain MIT engineers.
                                        Designed by Adam the Creator.
                                        Also - the Oven.
                                        Want to upgrade and change your SparkleMuffin? You can.
                                        <br/><br/>
                                        Each Muffinz also gets you access to Daily Muffinz, our daily thoughts and
                                        charts on interesting projects, performance benchmarking, and the broader NFT
                                        and crypto market.
                                    </p>
                                    <img src={trophy} className='trophy'/>
                                </div>
                                <div className='row-ac'>
                                    <img src={medal} className='medal'/>
                                    <p>
                                        The SparkleMuffinz is the first of a series of projects by New Forward Thinking
                                        (NFT), a group of individuals looking to build an impactful community with
                                        staying power. We have lots lined up for Muffinz hodlers including exclusive
                                        analytics tools, collaborations with artists and charities, meetups and more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: .5, transform: `translateY(${difference * .1}px`}}>
                            <div className='pt-2'>
                                <div className='row-ae mb-4'>
                                    <div className='hover14 shinetile hover-shadow' style={{marginLeft: 0}}>
                                        <figure>
                                            <img src={muffin}
                                                 style={{width: 255, height: 250, marginTop: -25, marginLeft: -45}}/>
                                        </figure>
                                    </div>
                                    <div>
                                        <p className='biglabel'>8,888</p>
                                        <p className='bigsublabel mt-0 mb-0'>Total</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row-ac'>
                                {ATTRIBUTES.map((attribute, i) =>
                                    <div className='mr-5'>
                                        <h4>{attribute.amt}</h4>
                                        <p className='mt-0'>{attribute.title}</p>
                                    </div>
                                )}
                            </div>
                            <p>
                                {ATTRIBUTES_SUBTEXT}
                            </p>
                        </div>

                    </div>
                    <img src={hanging} style={{
                        position: 'absolute',
                        top: -((difference - 1430) * .42),
                        right: '6vw',
                        width: 350
                    }}/>
                    <img src={paperplane} style={{
                        position: 'absolute',
                        top: -((difference - 900) * .4),
                        right: '3.5vw',
                        width: 500
                    }}/>
                    <img src={parachute} style={{
                        position: 'absolute',
                        top: -((difference - 3200) * .3),
                        right: '22vw',
                        width: 400
                    }}/>
                </div>
            </div>
        );
    }
}

export default SplashSection2;
