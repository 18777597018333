import './App.css';
import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {Collapse} from "react-collapse/lib/Collapse";
import {Link} from "react-router-dom";

const ROUTES = [
    {
        name: 'About',
        ref: 'about',
    },
    {
        name: 'Roadmap',
        ref: 'roadmap',
    },
    {
        name: 'Team',
        ref: 'team',
    },
    {
        name: 'FAQ',
        ref: 'faq',
    },
]

const variants = {
    collapsed: {
        height: 24
    },
    open: {
        height: 80
    }
}

const wait = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

class PantryNavbar extends Component {
    state = {
        hoveringNav: false,
        mobileNavOpen: false,
        numDots: 1
    }

    hoverNav = () => {
        if (this.props.scrollPosition >= 100) {
            wait(350).then(() => this.setState({hoveringNav: true}))
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({numDots: (this.state.numDots + 1) % 3}), 1000)

    }

    render() {
        if (this.props.mobile) {
            return (
                <div className='header-mobile'
                     style={{
                         height: (((this.props.scrollPosition <= 100) || this.state.hoveringNav) && !this.state.mobileNavOpen) ? 55 : this.state.mobileNavOpen ? '100vh' : 55,
                         backgroundColor: this.state.mobileNavOpen && '#000000C0',
                         borderBottom: this.props.scrollPosition <= 200 ? '3px solid #003459' : '3px solid #492726'
                     }}
                >
                    <Collapse isOpen={this.props.mintingScreenVisible}>
                        <div className='mint-page p-4' style={{
                            height: this.props.mintingScreenVisible ? '100vh' : 0,
                            opacity: !this.props.mintingScreenVisible && 0,
                            zIndex: 0
                        }}>
                            <h1 style={{fontSize: 20, lineHeight: '24px'}}>
                                Mint Your SparkleMuffinz
                            </h1>
                            <p className='mb-5' style={{fontSize: 16}}>
                                Minting is now live! We're so excited to welcome Muffin holders to
                                the fam. Thank you all so much for the support. If you have any questions about the
                                mint, or
                                just want to show off your new Muffinz, head on over to the Discord! The journey has
                                only just begun.
                            </p>
                            <div className='row-ae w-100'>
                                <div className='mr-3 mb-2'>
                                    <p className='biglabel' style={{fontSize: 40}}>8,888</p>
                                    <p className='bigsublabel mt-0 mb-0' style={{fontSize: 28}}>Total</p>
                                </div>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>4,903</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Minted</p>
                                </div>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>4,903</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Left</p>
                                </div>
                            </div>
                            <div className='row-ac w-100'>
                            </div>
                            <div className='row-ac mt-3 w-100'>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>3030</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>WL Left</p>
                                </div>
                                <div className='mr-5'>
                                    <h4 style={{fontSize: 24}}>0.5 ETH</h4>
                                    <p style={{fontSize: 16}} className='mt-0'>Mint Price</p>
                                </div>
                            </div>
                            <button className='w-100 linear-wipe pulse mt-2' style={{fontSize: 42}}>
                                <div className=' linear-wipe-pulse'>
                                    Mint Now!
                                </div>
                            </button>
                            <div onClick={this.props.mintPressed} className='mt-2 mint-close-mobile'>
                                Close
                            </div>
                        </div>
                    </Collapse>
                    <div className='row-ac-jb mt-1'>
                        <div className='pointer mb-1' onClick={() => this.props.scrollTo('top')}>
                            SparkleMuffinz
                        </div>
                        <div className='row-plain mb-05'>
                            <div className='connectwallet-mobile mint-here pulse' onClick={this.props.mintPressed}>
                                <div className='linear-wipe' style={{fontSize: 15}}>
                                    Mint Here!
                                </div>
                            </div>
                            <div className='relative mt-05' style={{height: 40, width: 40}}
                                 onClick={() => this.setState({mobileNavOpen: !this.state.mobileNavOpen})}>
                                <div className='absolute'
                                     style={{
                                         top: (0 - 35),
                                         fontSize: 50,
                                         left: 10,
                                         transition: '100ms ease',
                                         opacity: this.state.mobileNavOpen && 0,
                                     }}>
                                    _
                                </div>
                                <div className='absolute'
                                     style={{
                                         top: this.state.mobileNavOpen ? -22 : -27,
                                         fontSize: 50,
                                         left: this.state.mobileNavOpen ? -1 : 10,
                                         transition: '100ms ease',
                                         transform: this.state.mobileNavOpen && 'rotate(-45deg)',
                                         textShadow: this.state.mobileNavOpen && 'none'
                                     }}>
                                    _
                                </div>
                                <div className='absolute'
                                     style={{
                                         top: this.state.mobileNavOpen ? -22 : -19,
                                         fontSize: 50,
                                         left: this.state.mobileNavOpen ? 24 : 10,
                                         transition: '100ms ease',
                                         transform: this.state.mobileNavOpen && 'rotate(45deg)',
                                         textShadow: this.state.mobileNavOpen && 'none'
                                     }}>
                                    _
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul
                        className='mobile-navlist'
                        style={{listStyle: 'none', display: !this.state.mobileNavOpen && 'none'}}>
                        {ROUTES.map(route =>
                            <li className='m-0 p-0 mt-2 mb-2' onClick={() => {
                                this.props.scrollTo(route.ref);
                                this.setState({mobileNavOpen: false})
                            }}>
                                {route.name}
                            </li>
                        )}
                    </ul>
                </div>
            )
        } else return (
            <div
                onMouseEnter={this.hoverNav}
                onMouseLeave={() => this.setState({hoveringNav: false})}
                style={{
                    height: ((this.props.scrollPosition <= 100) || this.state.hoveringNav) ? 62 : 40,
                    borderBottom: this.props.scrollPosition <= 700 ? '3px solid #003459' : '3px solid #492726',
                }}
                className='header'
            >
                <div className='mint-page' style={{
                    height: this.props.mintingScreenVisible ? '100vh' : 0,
                    opacity: !this.props.mintingScreenVisible && 0
                }}>
                    <div className='w-70'>
                        <div className='mint-close' onClick={this.props.mintPressed}>
                            CLOSE
                        </div>
                        <h1>
                            Mint Your SparkleMuffinz
                        </h1>
                        <p className='mb-5'>
                            Minting is now live! We're so excited to welcome Muffin holders to the
                            fam. Thank you all so much for the support. If you have any questions about the mint, or
                            just want to show off your new Muffinz, head on over to the Discord! The journey has only
                            just begun.
                        </p>
                        <div className='row-plain'>
                            <div style={{flex: .5}}>
                                <div>
                                    <p className='biglabel'>8,888</p>
                                    <p className='bigsublabel mt-0 mb-0'>Total</p>
                                </div>
                                <div className='row-ac mt-3'>
                                    <div className='mr-5'>
                                        <h4>4,903</h4>
                                        <p className='mt-0'>Minted</p>
                                    </div>
                                    <div className='mr-5'>
                                        <h4>4,903</h4>
                                        <p className='mt-0'>Left</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='row-ac mt-4'>
                                        <div className='progress-vborder'/>
                                        <div className='w-100'>
                                            <div className='progress-hborder'/>
                                            <div className='progressbar-wrapper'>
                                                <div className='progressbar'>
                                                </div>
                                                <div className='highlight'/>
                                            </div>
                                            <div className='progress-hborder'/>
                                        </div>
                                        <div className='progress-vborder'/>
                                    </div>
                                    <div className='mt-2'>
                                        {this.props.title}.{".".repeat(this.state.numDots)}
                                    </div>
                                </div>
                            </div>
                            <div className='col-ac-jc' style={{flex: 1}}>
                                <div className='w-60'>
                                    <div className='row-plain'>
                                        <div className='mr-5'>
                                            <h4>3030</h4>
                                            <p className='mt-0'>WL Left</p>
                                        </div>
                                        <div>
                                            <h4>0.5 ETH</h4>
                                            <p className='mt-0'>Mint Price</p>
                                        </div>
                                    </div>
                                    <button className='w-100 linear-wipe pulse' style={{fontSize: 42}}>
                                        <div className=' linear-wipe-pulse'>
                                            Mint Now!
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='navtitle pointer'>
                    <Link to='/' style={{textDecoration: 'none', color: 'white'}}>
                        SparkleMuffinz
                    </Link>
                </div>
                <div className='row-ac'>
                    <li style={{listStyle: 'none', position: 'relative', zIndex: 999999}} className='mr-6'>
                        <Link to='/' style={{textDecoration: 'none', color: 'white'}}>
                            Back Home
                        </Link>
                    </li>
                    <div className='row-ac'>
                        <a href='https://discord.gg/5gM2BxzZJ4' target='_blank'
                           style={{textDecoration: 'none', color: 'white'}}>
                            <FontAwesomeIcon icon={faDiscord} className='mr-2 pointer discord-icon'/>
                        </a>
                        <a href='https://twitter.com/muffinznft' target='_blank'
                           style={{textDecoration: 'none', color: 'white'}}>
                            <FontAwesomeIcon icon={faTwitter} className='pointer twitter-icon'/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default PantryNavbar;
